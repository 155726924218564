// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/agentapi/agent/v1/agent.proto" (package "agent.v1", syntax proto3),// tslint:disable
import { SecretAgentService } from "./agent";
import type { UserRequest } from "./agent";
import type { BetaRequest } from "./agent";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PublicAgentService } from "./agent";
import type { UserSetting } from "./agent";
import type { KamiRequest } from "./agent";
import type { RedeemRequest } from "./agent";
import type { AgentGameRequest } from "./agent";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Response } from "../../../commonTypes/common/v1/response";
import type { User } from "../../../commonTypes/common/v1/user";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service agent.v1.PublicAgentService
 */
export interface IPublicAgentServiceClient {
    /**
     * @generated from protobuf rpc: Connect(common.v1.User) returns (common.v1.Response);
     */
    connect(input: User, options?: RpcOptions): UnaryCall<User, Response>;
    /**
     * @generated from protobuf rpc: Game(agent.v1.AgentGameRequest) returns (common.v1.Response);
     */
    game(input: AgentGameRequest, options?: RpcOptions): UnaryCall<AgentGameRequest, Response>;
    /**
     * @generated from protobuf rpc: Redeem(agent.v1.RedeemRequest) returns (common.v1.Response);
     */
    redeem(input: RedeemRequest, options?: RpcOptions): UnaryCall<RedeemRequest, Response>;
    /**
     * @generated from protobuf rpc: Kami(agent.v1.KamiRequest) returns (common.v1.Response);
     */
    kami(input: KamiRequest, options?: RpcOptions): UnaryCall<KamiRequest, Response>;
    /**
     * @generated from protobuf rpc: Account(agent.v1.UserSetting) returns (common.v1.Response);
     */
    account(input: UserSetting, options?: RpcOptions): UnaryCall<UserSetting, Response>;
}
/**
 * @generated from protobuf service agent.v1.PublicAgentService
 */
export class PublicAgentServiceClient implements IPublicAgentServiceClient, ServiceInfo {
    typeName = PublicAgentService.typeName;
    methods = PublicAgentService.methods;
    options = PublicAgentService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Connect(common.v1.User) returns (common.v1.Response);
     */
    connect(input: User, options?: RpcOptions): UnaryCall<User, Response> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<User, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Game(agent.v1.AgentGameRequest) returns (common.v1.Response);
     */
    game(input: AgentGameRequest, options?: RpcOptions): UnaryCall<AgentGameRequest, Response> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<AgentGameRequest, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Redeem(agent.v1.RedeemRequest) returns (common.v1.Response);
     */
    redeem(input: RedeemRequest, options?: RpcOptions): UnaryCall<RedeemRequest, Response> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RedeemRequest, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Kami(agent.v1.KamiRequest) returns (common.v1.Response);
     */
    kami(input: KamiRequest, options?: RpcOptions): UnaryCall<KamiRequest, Response> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<KamiRequest, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Account(agent.v1.UserSetting) returns (common.v1.Response);
     */
    account(input: UserSetting, options?: RpcOptions): UnaryCall<UserSetting, Response> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserSetting, Response>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service agent.v1.SecretAgentService
 */
export interface ISecretAgentServiceClient {
    /**
     * @generated from protobuf rpc: Beta(agent.v1.BetaRequest) returns (common.v1.Response);
     */
    beta(input: BetaRequest, options?: RpcOptions): UnaryCall<BetaRequest, Response>;
    /**
     * @generated from protobuf rpc: User(agent.v1.UserRequest) returns (common.v1.Response);
     */
    user(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, Response>;
}
/**
 * @generated from protobuf service agent.v1.SecretAgentService
 */
export class SecretAgentServiceClient implements ISecretAgentServiceClient, ServiceInfo {
    typeName = SecretAgentService.typeName;
    methods = SecretAgentService.methods;
    options = SecretAgentService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Beta(agent.v1.BetaRequest) returns (common.v1.Response);
     */
    beta(input: BetaRequest, options?: RpcOptions): UnaryCall<BetaRequest, Response> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<BetaRequest, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: User(agent.v1.UserRequest) returns (common.v1.Response);
     */
    user(input: UserRequest, options?: RpcOptions): UnaryCall<UserRequest, Response> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserRequest, Response>("unary", this._transport, method, opt, input);
    }
}
