// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/commonTypes/common/v1/game.proto" (package "common.v1", syntax proto3),// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message common.v1.Game
 */
export interface Game {
    /**
     * @generated from protobuf field: string uuid = 1;
     */
    uuid: string;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
    /**
     * @generated from protobuf field: string owner = 3;
     */
    owner: string;
    /**
     * @generated from protobuf field: common.v1.Game.Fruit fruit = 4;
     */
    fruit: Game_Fruit;
    /**
     * @generated from protobuf field: string version = 5;
     */
    version: string;
}
/**
 * @generated from protobuf enum common.v1.Game.Fruit
 */
export enum Game_Fruit {
    /**
     * @generated from protobuf enum value: dice = 0;
     */
    dice = 0,
    /**
     * @generated from protobuf enum value: float = 1;
     */
    float = 1,
    /**
     * @generated from protobuf enum value: house = 2;
     */
    house = 2,
    /**
     * @generated from protobuf enum value: hook = 3;
     */
    hook = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Game$Type extends MessageType<Game> {
    constructor() {
        super("common.v1.Game", [
            { no: 1, name: "uuid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "owner", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "fruit", kind: "enum", T: () => ["common.v1.Game.Fruit", Game_Fruit] },
            { no: 5, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Game>): Game {
        const message = { uuid: "", key: "", owner: "", fruit: 0, version: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Game>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Game): Game {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uuid */ 1:
                    message.uuid = reader.string();
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                case /* string owner */ 3:
                    message.owner = reader.string();
                    break;
                case /* common.v1.Game.Fruit fruit */ 4:
                    message.fruit = reader.int32();
                    break;
                case /* string version */ 5:
                    message.version = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Game, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uuid = 1; */
        if (message.uuid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uuid);
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        /* string owner = 3; */
        if (message.owner !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.owner);
        /* common.v1.Game.Fruit fruit = 4; */
        if (message.fruit !== 0)
            writer.tag(4, WireType.Varint).int32(message.fruit);
        /* string version = 5; */
        if (message.version !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.v1.Game
 */
export const Game = new Game$Type();
