// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/configapi/config/v1/config.proto" (package "config.v1", syntax proto3),// tslint:disable
import { Response } from "../../../commonTypes/common/v1/response";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "../../../commonTypes/common/v1/user";
/**
 * @generated from protobuf message config.v1.ConfigRequest
 */
export interface ConfigRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: config.v1.ConfigRequest.Scene scene = 2;
     */
    scene: ConfigRequest_Scene;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
}
/**
 * @generated from protobuf enum config.v1.ConfigRequest.Scene
 */
export enum ConfigRequest_Scene {
    /**
     * @generated from protobuf enum value: ned3 = 0;
     */
    ned3 = 0,
    /**
     * @generated from protobuf enum value: keyboard = 1;
     */
    keyboard = 1,
    /**
     * @generated from protobuf enum value: dice = 2;
     */
    dice = 2,
    /**
     * @generated from protobuf enum value: float = 3;
     */
    float = 3,
    /**
     * @generated from protobuf enum value: house = 4;
     */
    house = 4,
    /**
     * @generated from protobuf enum value: hook = 5;
     */
    hook = 5
}
/**
 * @generated from protobuf message config.v1.Attribute
 */
export interface Attribute {
    /**
     * @generated from protobuf field: config.v1.Attribute.Data type = 1;
     */
    type: Attribute_Data;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf enum config.v1.Attribute.Data
 */
export enum Attribute_Data {
    /**
     * @generated from protobuf enum value: bool = 0;
     */
    bool = 0,
    /**
     * @generated from protobuf enum value: string = 1;
     */
    string = 1,
    /**
     * @generated from protobuf enum value: number = 2;
     */
    number = 2,
    /**
     * @generated from protobuf enum value: var = 3;
     */
    var = 3
}
/**
 * @generated from protobuf message config.v1.Component
 */
export interface Component {
    /**
     * @generated from protobuf field: string Name = 1 [json_name = "Name"];
     */
    name: string;
    /**
     * @generated from protobuf field: map<string, config.v1.Attribute> Values = 2 [json_name = "Values"];
     */
    values: {
        [key: string]: Attribute;
    };
}
/**
 * @generated from protobuf message config.v1.Element
 */
export interface Element {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: repeated config.v1.Component comps = 2;
     */
    comps: Component[];
}
// @generated message type with reflection information, may provide speed optimized methods
class ConfigRequest$Type extends MessageType<ConfigRequest> {
    constructor() {
        super("config.v1.ConfigRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "scene", kind: "enum", T: () => ["config.v1.ConfigRequest.Scene", ConfigRequest_Scene] },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConfigRequest>): ConfigRequest {
        const message = { scene: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfigRequest): ConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* config.v1.ConfigRequest.Scene scene */ 2:
                    message.scene = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* config.v1.ConfigRequest.Scene scene = 2; */
        if (message.scene !== 0)
            writer.tag(2, WireType.Varint).int32(message.scene);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message config.v1.ConfigRequest
 */
export const ConfigRequest = new ConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Attribute$Type extends MessageType<Attribute> {
    constructor() {
        super("config.v1.Attribute", [
            { no: 1, name: "type", kind: "enum", T: () => ["config.v1.Attribute.Data", Attribute_Data] },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Attribute>): Attribute {
        const message = { type: 0, value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Attribute>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Attribute): Attribute {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* config.v1.Attribute.Data type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Attribute, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* config.v1.Attribute.Data type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message config.v1.Attribute
 */
export const Attribute = new Attribute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Component$Type extends MessageType<Component> {
    constructor() {
        super("config.v1.Component", [
            { no: 1, name: "Name", kind: "scalar", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Values", kind: "map", jsonName: "Values", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Attribute } }
        ]);
    }
    create(value?: PartialMessage<Component>): Component {
        const message = { name: "", values: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Component>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Component): Component {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Name = 1 [json_name = "Name"];*/ 1:
                    message.name = reader.string();
                    break;
                case /* map<string, config.v1.Attribute> Values = 2 [json_name = "Values"];*/ 2:
                    this.binaryReadMap2(message.values, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: Component["values"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Component["values"] | undefined, val: Component["values"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Attribute.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field config.v1.Component.Values");
            }
        }
        map[key ?? ""] = val ?? Attribute.create();
    }
    internalBinaryWrite(message: Component, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Name = 1 [json_name = "Name"]; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* map<string, config.v1.Attribute> Values = 2 [json_name = "Values"]; */
        for (let k of Object.keys(message.values)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Attribute.internalBinaryWrite(message.values[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message config.v1.Component
 */
export const Component = new Component$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Element$Type extends MessageType<Element> {
    constructor() {
        super("config.v1.Element", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "comps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Component }
        ]);
    }
    create(value?: PartialMessage<Element>): Element {
        const message = { name: "", comps: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Element>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Element): Element {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated config.v1.Component comps */ 2:
                    message.comps.push(Component.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Element, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated config.v1.Component comps = 2; */
        for (let i = 0; i < message.comps.length; i++)
            Component.internalBinaryWrite(message.comps[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message config.v1.Element
 */
export const Element = new Element$Type();
/**
 * @generated ServiceType for protobuf service config.v1.ConfigService
 */
export const ConfigService = new ServiceType("config.v1.ConfigService", [
    { name: "Connect", options: {}, I: User, O: Response },
    { name: "Fetch", serverStreaming: true, options: {}, I: ConfigRequest, O: Element }
]);
