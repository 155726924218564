// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/commonTypes/common/v1/response.proto" (package "common.v1", syntax proto3),// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message common.v1.Response
 */
export interface Response {
    /**
     * @generated from protobuf field: bool Success = 1 [json_name = "Success"];
     */
    success: boolean;
    /**
     * @generated from protobuf field: string Details = 2 [json_name = "Details"];
     */
    details: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Response$Type extends MessageType<Response> {
    constructor() {
        super("common.v1.Response", [
            { no: 1, name: "Success", kind: "scalar", jsonName: "Success", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "Details", kind: "scalar", jsonName: "Details", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Response>): Response {
        const message = { success: false, details: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Response>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Response): Response {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool Success = 1 [json_name = "Success"];*/ 1:
                    message.success = reader.bool();
                    break;
                case /* string Details = 2 [json_name = "Details"];*/ 2:
                    message.details = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Response, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool Success = 1 [json_name = "Success"]; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        /* string Details = 2 [json_name = "Details"]; */
        if (message.details !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.details);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message common.v1.Response
 */
export const Response = new Response$Type();
