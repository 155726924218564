// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/configapi/config/v1/config.proto" (package "config.v1", syntax proto3),// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ConfigService } from "./config";
import type { Element } from "./config";
import type { ConfigRequest } from "./config";
import type { ServerStreamingCall } from "@protobuf-ts/runtime-rpc";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Response } from "../../../commonTypes/common/v1/response";
import type { User } from "../../../commonTypes/common/v1/user";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service config.v1.ConfigService
 */
export interface IConfigServiceClient {
    /**
     * @generated from protobuf rpc: Connect(common.v1.User) returns (common.v1.Response);
     */
    connect(input: User, options?: RpcOptions): UnaryCall<User, Response>;
    /**
     * @generated from protobuf rpc: Fetch(config.v1.ConfigRequest) returns (stream config.v1.Element);
     */
    fetch(input: ConfigRequest, options?: RpcOptions): ServerStreamingCall<ConfigRequest, Element>;
}
/**
 * @generated from protobuf service config.v1.ConfigService
 */
export class ConfigServiceClient implements IConfigServiceClient, ServiceInfo {
    typeName = ConfigService.typeName;
    methods = ConfigService.methods;
    options = ConfigService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Connect(common.v1.User) returns (common.v1.Response);
     */
    connect(input: User, options?: RpcOptions): UnaryCall<User, Response> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<User, Response>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Fetch(config.v1.ConfigRequest) returns (stream config.v1.Element);
     */
    fetch(input: ConfigRequest, options?: RpcOptions): ServerStreamingCall<ConfigRequest, Element> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ConfigRequest, Element>("serverStreaming", this._transport, method, opt, input);
    }
}
