// @generated by protobuf-ts 2.4.0,// @generated from protobuf file "proto/agentapi/agent/v1/agent.proto" (package "agent.v1", syntax proto3),// tslint:disable
import { Response } from "../../../commonTypes/common/v1/response";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Game } from "../../../commonTypes/common/v1/game";
import { User } from "../../../commonTypes/common/v1/user";
/**
 * @generated from protobuf message agent.v1.UserSetting
 */
export interface UserSetting {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: agent.v1.UserSetting.Setting target = 2;
     */
    target: UserSetting_Setting;
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string;
}
/**
 * @generated from protobuf enum agent.v1.UserSetting.Setting
 */
export enum UserSetting_Setting {
    /**
     * @generated from protobuf enum value: uname = 0;
     */
    uname = 0,
    /**
     * @generated from protobuf enum value: email = 1;
     */
    email = 1,
    /**
     * @generated from protobuf enum value: pword = 2;
     */
    pword = 2
}
/**
 * @generated from protobuf message agent.v1.AgentGameRequest
 */
export interface AgentGameRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: agent.v1.AgentGameRequest.Operation op = 2;
     */
    op: AgentGameRequest_Operation;
    /**
     * @generated from protobuf field: common.v1.Game game = 3;
     */
    game?: Game;
}
/**
 * @generated from protobuf enum agent.v1.AgentGameRequest.Operation
 */
export enum AgentGameRequest_Operation {
    /**
     * @generated from protobuf enum value: create = 0;
     */
    create = 0,
    /**
     * @generated from protobuf enum value: join = 1;
     */
    join = 1,
    /**
     * @generated from protobuf enum value: spectate = 2;
     */
    spectate = 2,
    /**
     * @generated from protobuf enum value: ready = 3;
     */
    ready = 3,
    /**
     * @generated from protobuf enum value: unready = 4;
     */
    unready = 4,
    /**
     * @generated from protobuf enum value: start = 5;
     */
    start = 5,
    /**
     * @generated from protobuf enum value: stop = 6;
     */
    stop = 6
}
/**
 * @generated from protobuf message agent.v1.RedeemRequest
 */
export interface RedeemRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
/**
 * @generated from protobuf message agent.v1.KamiRequest
 */
export interface KamiRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: string kami = 2;
     */
    kami: string;
}
/**
 * @generated from protobuf message agent.v1.BetaRequest
 */
export interface BetaRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
/**
 * @generated from protobuf message agent.v1.UserRequest
 */
export interface UserRequest {
    /**
     * @generated from protobuf field: common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: agent.v1.UserRequest.Operation opt = 2;
     */
    opt: UserRequest_Operation;
}
/**
 * @generated from protobuf enum agent.v1.UserRequest.Operation
 */
export enum UserRequest_Operation {
    /**
     * @generated from protobuf enum value: create = 0;
     */
    create = 0,
    /**
     * @generated from protobuf enum value: login = 1;
     */
    login = 1,
    /**
     * @generated from protobuf enum value: logout = 2;
     */
    logout = 2,
    /**
     * @generated from protobuf enum value: delete = 3;
     */
    delete = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class UserSetting$Type extends MessageType<UserSetting> {
    constructor() {
        super("agent.v1.UserSetting", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "target", kind: "enum", T: () => ["agent.v1.UserSetting.Setting", UserSetting_Setting] },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserSetting>): UserSetting {
        const message = { target: 0, value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserSetting>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserSetting): UserSetting {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* agent.v1.UserSetting.Setting target */ 2:
                    message.target = reader.int32();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserSetting, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* agent.v1.UserSetting.Setting target = 2; */
        if (message.target !== 0)
            writer.tag(2, WireType.Varint).int32(message.target);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.UserSetting
 */
export const UserSetting = new UserSetting$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgentGameRequest$Type extends MessageType<AgentGameRequest> {
    constructor() {
        super("agent.v1.AgentGameRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "op", kind: "enum", T: () => ["agent.v1.AgentGameRequest.Operation", AgentGameRequest_Operation] },
            { no: 3, name: "game", kind: "message", T: () => Game }
        ]);
    }
    create(value?: PartialMessage<AgentGameRequest>): AgentGameRequest {
        const message = { op: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgentGameRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgentGameRequest): AgentGameRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* agent.v1.AgentGameRequest.Operation op */ 2:
                    message.op = reader.int32();
                    break;
                case /* common.v1.Game game */ 3:
                    message.game = Game.internalBinaryRead(reader, reader.uint32(), options, message.game);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgentGameRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* agent.v1.AgentGameRequest.Operation op = 2; */
        if (message.op !== 0)
            writer.tag(2, WireType.Varint).int32(message.op);
        /* common.v1.Game game = 3; */
        if (message.game)
            Game.internalBinaryWrite(message.game, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.AgentGameRequest
 */
export const AgentGameRequest = new AgentGameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedeemRequest$Type extends MessageType<RedeemRequest> {
    constructor() {
        super("agent.v1.RedeemRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedeemRequest>): RedeemRequest {
        const message = { key: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RedeemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedeemRequest): RedeemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedeemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.RedeemRequest
 */
export const RedeemRequest = new RedeemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class KamiRequest$Type extends MessageType<KamiRequest> {
    constructor() {
        super("agent.v1.KamiRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "kami", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<KamiRequest>): KamiRequest {
        const message = { kami: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<KamiRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: KamiRequest): KamiRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string kami */ 2:
                    message.kami = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: KamiRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string kami = 2; */
        if (message.kami !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.kami);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.KamiRequest
 */
export const KamiRequest = new KamiRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BetaRequest$Type extends MessageType<BetaRequest> {
    constructor() {
        super("agent.v1.BetaRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BetaRequest>): BetaRequest {
        const message = { key: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BetaRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BetaRequest): BetaRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BetaRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.BetaRequest
 */
export const BetaRequest = new BetaRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRequest$Type extends MessageType<UserRequest> {
    constructor() {
        super("agent.v1.UserRequest", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "opt", kind: "enum", T: () => ["agent.v1.UserRequest.Operation", UserRequest_Operation] }
        ]);
    }
    create(value?: PartialMessage<UserRequest>): UserRequest {
        const message = { opt: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRequest): UserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* agent.v1.UserRequest.Operation opt */ 2:
                    message.opt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* agent.v1.UserRequest.Operation opt = 2; */
        if (message.opt !== 0)
            writer.tag(2, WireType.Varint).int32(message.opt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message agent.v1.UserRequest
 */
export const UserRequest = new UserRequest$Type();
/**
 * @generated ServiceType for protobuf service agent.v1.PublicAgentService
 */
export const PublicAgentService = new ServiceType("agent.v1.PublicAgentService", [
    { name: "Connect", options: {}, I: User, O: Response },
    { name: "Game", options: {}, I: AgentGameRequest, O: Response },
    { name: "Redeem", options: {}, I: RedeemRequest, O: Response },
    { name: "Kami", options: {}, I: KamiRequest, O: Response },
    { name: "Account", options: {}, I: UserSetting, O: Response }
]);
/**
 * @generated ServiceType for protobuf service agent.v1.SecretAgentService
 */
export const SecretAgentService = new ServiceType("agent.v1.SecretAgentService", [
    { name: "Beta", options: {}, I: BetaRequest, O: Response },
    { name: "User", options: {}, I: UserRequest, O: Response }
]);
