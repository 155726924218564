// author: zeptotenshi

/* import grpc */
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";

/* import services*/
import { ConfigServiceClient } from "./proto/configapi/config/v1/config.client";
import { PublicAgentServiceClient } from "./proto/agentapi/agent/v1/agent.client";

/////////////////////////////////////////////////////////////////////////////////
let agent_transport = new GrpcWebFetchTransport({
  baseUrl: "/agent",
});
window.agent_service = new PublicAgentServiceClient(agent_transport);

/////////////////////////////////////////////////////////////////////////////////
let config_transport = new GrpcWebFetchTransport({
  baseUrl: "/config",
});
window.config_service = new ConfigServiceClient(config_transport);

//////////////////////////////////////////////////////////////////////////////////////
async function ned3Run() {
  'use strict';

  if (prodEnv) {
    const scene = document.getElementById("ned3_scene");
    scene.removeComponent("inspector");
  }

  const go = new TinyGo();
  const NED3_WASM_URL = 'wasm/ned3.wasm';

  if (!WebAssembly.instantiateStreaming) {
    WebAssembly.instantiateStreaming = async (resp, importObject) => {
      const source = await (await resp).arrayBuffer();
      return await WebAssembly.instantiate(source, importObject);
    };
  }

  async function init() {
    let result = await WebAssembly.instantiateStreaming(fetch(NED3_WASM_URL), go.importObject)
    go.run(result.instance);
  }
  init();
}

window.onload = ned3Run();
